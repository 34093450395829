// import Swiper from "swiper";
// import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";

// // configure Swiper to use modules
// SwiperCore.use([Navigation, Pagination, Autoplay]);

// const bannerSwiper = new Swiper(".banner-swiper", {
// 	autoplay: {
// 		delay: 5000
// 	},
// 	pagination: {
// 		el: ".swiper-pagination",
// 	},
// 	// Navigation arrows
// 	navigation: {
// 		nextEl: ".banner-button-next",
// 		prevEl: ".banner-button-prev",
// 	},
// });
window.onload = f;
function f() {
	var gclid = GetRequest("gclid");
	console.log(gclid, '###########')
    if(gclid!=""){
        localStorage.setItem("gclid", gclid);
    }
	
}
function GetRequest(value) {
	//url例子：www.bicycle.com?id="123456"&Name="bicycle"；
	var url = decodeURI(location.search); //?id="123456"&Name="bicycle";
	var object = {};
	if (url.indexOf("?") != -1) {
		//url中存在问号，也就说有参数。
		var str = url.substr(1); //得到?后面的字符串
		var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",Name="bicycle"];
		for (var i = 0; i < strs.length; i++) {
			object[strs[i].split("=")[0]] = strs[i].split("=")[1];
		}
	}
	return object[value]||"";
}
